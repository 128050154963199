import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Prompt from './Prompt';
import Main from './Main';

function Index() {
  // Check if we've been redirected back after getting a code
  let url = window.location.href;
  let fullCode = url.split('/?code=')[1];
  console.log(fullCode);

  if(!fullCode){
    // First time on the site or error prompt for login
    return <Prompt></Prompt>;
  } else {
    // Send code to main screen
    let code = fullCode.substring(0, fullCode.length - 2);
    return <Main code= {code}></Main>;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Index></Index>
  </React.StrictMode>,
  document.getElementById('root')
);

