import React from 'react';
import './Main.css';
import Container from '@material-ui/core/Container';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getAccessToken, getData} from './requests';


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
    }

    async componentDidMount() {
        var accessToken = await getAccessToken(this.props.code);
        var data = await getData(accessToken)
        this.setState({ data: data });
    }

    renderPictures() {
        if(this.state.data){
        var result = [];
        for (var pic of this.state.data) {
            result.push(
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={pic.media_url}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <p>{pic.caption}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            )
        }}
        else{
           result = <CircularProgress />;
        }
        return result;
    }

    render() {
        return (
            <>
            <Container className="Main" maxWidth="sm">
                    <Carousel className="carousel">
                        {this.renderPictures()}
                    </Carousel>
            </Container>

            </>
        );
    }
}

export default Main;
