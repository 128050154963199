import React from 'react';
import './Prompt.css';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

function Prompt() {
    // Set up appropriate OAuth information to have user verify and get access token
    const app_id = 286798525656453;
    const redirect_uri = 'https://managerly-fcaab.firebaseapp.com/';
    let url = `https://api.instagram.com/oauth/authorize?client_id=${app_id}&redirect_uri=${redirect_uri}&scope=user_profile,user_media&response_type=code`;
    return (
        <>
            <Container className="Prompt" maxWidth="sm">
                <h1>Welcome to Instagram Media-Browser!</h1>
                <p>Login in below to see your content</p>
                <div className="buttonWrapper">
                    <Button variant="contained" color="primary" href={url}> Start </Button>
                </div>
            </Container>
        </>
    );
}

export default Prompt;
