async function getAccessToken(code) {
    const client_id = '286798525656453';
    const client_secret = '75359beb48ead31aa83bc03ccf8d618a';
    const redirect_uri = 'https://managerly-fcaab.firebaseapp.com/';

    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", client_id);
    urlencoded.append("client_secret", client_secret);
    urlencoded.append("code", code);
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("redirect_uri", redirect_uri);

    let url = 'https://api.instagram.com/oauth/access_token';
    try {
        let response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: urlencoded
            });
        let responseObj = await response.json();
        console.log(responseObj);
        return responseObj.access_token;
    } catch (error) {
        alert('Authentication failed, please reload and try again\nSee log for error');
        console.log(error);
    }
}

async function getData(token) {
    let fields = "id,caption,media_url";
    let url = `https://graph.instagram.com/me/media?fields=${fields}&access_token=${token}`;
    try {
        let response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        let responseObj = await response.json();
        console.log(responseObj);
        return responseObj.data;
    } catch (error) {
        alert('Got auth token but failed to get data, please reload and try again\nSee log for error');
        console.log(error);
    }

}

export { getAccessToken, getData };